$(function () {
    if ($(".mainContents").hasClass("page-items")) {
        // url encode 
        function createShareLink(url, params) {
            var encoded_params = [];
            for (var key in params) {
                var value = encodeURIComponent(params[key]);
                encoded_params.push(key + "=" + value);
            }
            return url + "?" + encoded_params.join("&");
        }

        var base_urlLine = "https://timeline.line.me/social-plugin/share";

        var this_url = location.href;
        var parameters = {
            url: this_url,
        }
        $('.items__topShareCard--line').attr('href', createShareLink(base_urlLine, parameters));

        $('.items__topTags__remove').each(function () {
            $(this).children('img').prependTo('.items__topTags');
            $(this).remove();
        })
        $('#M_nameCustom').prependTo('.nameoption');
        $('.noshi__title').on('click', function () {
            $(this).toggleClass('noshi__title--active');
            $(this).next().fadeToggle();
        });
        var windowWidth = $(window).width();
        var windowSm = 812;
        if (windowWidth <= windowSm) {
            //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
        } else {

        }
    }
});