$(function () {
    if ($(".mainContents").hasClass("page-basket")) {

        $(window).on('load',function(){
            $('.basket-quantity span').siblings('.btn-small').addClass('none');
        });
        var windowWidth = $(window).width();
        var windowSm = 812;
        if (windowWidth <= windowSm) {
            //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
        } else {}

    }
});