var outhover;

$(function () {
  $('.l-shopBtn--share').on('click', function () {
    $('.items__topShare').fadeToggle();
  });

  var headerHight = 120;
  //*ページ内リンクの指定

  $("a[href^='#']").click(function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top - headerHight;
    $("html, body").animate({
      scrollTop: position
    }, 550, "swing");
    return false;
  });

  //*ページ外リンクの指定*/
  var headerHeight = 120;
  var urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);

    setTimeout(function () {
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({
        scrollTop: position
      }, 500);
    }, 100);
  }

  // imgswitch
  var $replaceElem = $(".switch");
  var replaceSp = "_sp.";
  var replacePc = "_pc.";
  var replaceWidth = 480;

  function imageSwitch() {
    var windowWidth = parseInt($(window).width());
    $replaceElem.each(function () {
      var $this = $(this);

      if (windowWidth >= replaceWidth) {
        $this.attr("src", $this.attr("src").replace(replaceSp, replacePc));
      } else {
        $this.attr("src", $this.attr("src").replace(replacePc, replaceSp));
      }
    });
  }
  lazyload();
  imageSwitch();
  var resizeTimer;
  $(window).on("resize", function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      imageSwitch();
    }, 200);
  });

  // nav
  $('.l-header__btn--open').on('click', function () {
    $('.l-header__bottom').fadeIn();
  });
  $('.l-header__btn--close').on('click', function () {
    $('.l-header__bottom').fadeOut();
  });
  $('#recent .line').remove();
});


// sp,pc分岐
var windowWidth = $(window).width();
var windowSm = 812;
if (windowWidth <= windowSm) {
  $(function () {
    $('.l-main__information').on('click', function () {
      $(this).fadeOut();
    });
  });
} else {
  $(function () {
    $(window).on('scroll load', function () {
      (scroll = $(window).scrollTop()), (height = 300);
      if (scroll > height) {
        $('.l-main__link').addClass('l-main__link--active');
      } else {
        $('.l-main__link').removeClass('l-main__link--active');
      }
    });
    // var rellax = new Rellax('.rellax', {
    //   vertical: true, 
    //   horizontal: false
    // });

  });
}

// アコーディオン

$(function(){
  $(".text_aco1").on("click", function() {
    console.log('test1');
  $(this).next().slideToggle();
    $(".text_aco1").toggleClass('active');
    console.log('test2');
  });
});

$(function(){
  $(".text_aco2").on("click", function() {
  $(this).next().slideToggle();
    $(".text_aco2").toggleClass('active')
  });
});

$(function(){
  $(".text_aco3").on("click", function() {
  $(this).next().slideToggle();
    $(".text_aco3").toggleClass('active')
  });
});

$(function(){
  $(".text_aco4").on("click", function() {
  $(this).next().slideToggle();
    $(".text_aco4").toggleClass('active')
  });
});
$(function(){
  $(".btn_ac").on("click", function() {
    $(".ac_area").toggleClass("active");
  });
});