$(function () {
    if ($(".mainContents").hasClass("page-matsuri")) {
        var windowWidth = $(window).width();
        var windowSm = 812;
        if (windowWidth <= windowSm) {
            //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
            $('.open__next').on('click',function(){
                $(this).toggleClass('open__next--isopen');
                $(this).parent().find('.click-none').fadeToggle();
                
            });
        } else {

        }
    }
});