$(function () {
    if ($(".mainContents").hasClass("page-twentyfour")) {
        var now = new Date();

        function LoadProc() {
            var toDoubleDigits = function (num) {
                num += "";
                if (num.length === 1) {
                    num = "0" + num;
                }
                return num;
            };
            const timeTarget = document.getElementById("DateTime");
            const Hour = toDoubleDigits(now.getHours());
            const Min = toDoubleDigits(now.getMinutes());
            timeTarget.innerHTML = Hour + ":" + Min;
        }
        LoadProc();

        $('.tf-main').each(function () {
            const now_hour = new Date().getHours();
            if (0 <= now_hour && now_hour < 2) {
                $(this).addClass('tf-main--eq11');
            } else if (2 <= now_hour && now_hour < 4) {
                $(this).addClass('tf-main--eq12');
            } else if (4 <= now_hour && now_hour < 6) {
                $(this).addClass('tf-main--eq01');
            } else if (6 <= now_hour && now_hour < 8) {
                $(this).addClass('tf-main--eq02');
            } else if (8 <= now_hour && now_hour < 10) {
                $(this).addClass('tf-main--eq03');
            } else if (10 <= now_hour && now_hour < 12) {
                $(this).addClass('tf-main--eq04');
            } else if (12 <= now_hour && now_hour < 14) {
                $(this).addClass('tf-main--eq05');
            } else if (14 <= now_hour && now_hour < 16) {
                $(this).addClass('tf-main--eq06');
            } else if (16 <= now_hour && now_hour < 18) {
                $(this).addClass('tf-main--eq07');
            } else if (18 <= now_hour && now_hour < 20) {
                $(this).addClass('tf-main--eq08');
            } else if (20 <= now_hour && now_hour < 22) {
                $(this).addClass('tf-main--eq09');
            } else {
                $(this).addClass('tf-main--eq10');
            }
        });

        var windowWidth = $(window).width();
        var windowSm = 812;
        if (windowWidth <= windowSm) {
            //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
        } else {

        }
    }
});