$(function () {

  if ($(".mainContents").hasClass("page-top")) {


  const keyName = 'visited';
  const keyValue = true;
  
  if (!sessionStorage.getItem(keyName)) {
      //sessionStorageにキーと値を追加
      sessionStorage.setItem(keyName, keyValue);
      $('.top__loading').css('display','block').css('height',innerHeight);
      $(window).on('load',function(){
        setTimeout(function(){
          $('.top__loading').fadeOut();
        },1500);
      })
      $('.index__popup__wrapper').css('height',innerHeight);
      $('.index__popup__closer').on('click',function(){
        $('.index__popup__wrapper').fadeOut();
      });
  } else {
      //ここに通常アクセス時の処理
      $('.index__popup__wrapper').css('display','none');
  }

    $('.top__slider').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      centerMode: true,
      centerPadding: '15%',
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      pauseOnHover: false,
      focusOnSelect: true,
      pauseOnFocus: false
    });
    $('.top__searchInput').on('click', function () {
      $('.l-header__bottomBlockInput').remove();
      
    });
    $('.top__searchInput').keydown(function(event) {
      if (event.key === 'Enter'){
        console.log('keydown');
        MakeShop_TopSearch(event, 'MakeShopTopSearchInput');
      }
    });
    $('.top__topicsSlides').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,

      responsive: [{
        breakpoint: 480,
        settings: {
          centerMode: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0'
        }
      }]
    });

    var windowWidth = $(window).width();
    var windowSm = 812;
    if (windowWidth <= windowSm) {
      //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
    } else {

    }
  }
});