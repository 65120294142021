

$(function () {
    if ($(".mainContents").hasClass("page-category")) {
        $("td").html(function (i, html) {
            return html.replace(/&nbsp;/g, '');
        });
        console.log('test')
        var windowWidth = $(window).width();
        var windowSm = 812;
        if (windowWidth <= windowSm) {
            //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
        } else {

        }
    }
});